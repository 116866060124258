@import "./typography.css";
@import "./quill-editor.css";
@import "./tooltip.css";
@import "./toggle-box.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-mint-green: #9CFFC5;
}

/* Add custom utility class for our mint-green color */
.text-mint-green {
  color: var(--color-mint-green);
}

.bg-mint-green {
  background-color: var(--color-mint-green);
}

/* Add padding for devices with a safe area (like iPhone X and newer) */
.safe-area-bottom {
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
}

html,
body,
#__next {
  @apply bg-zettlor-new-off-white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* Only remove focus box-shadow for react-select inputs */
.react-select__input:focus {
  box-shadow: none;
}

*:not(:is(svg, svg *)):not(:is(button, button *)):not(:is(a, a *)) {
  @apply tracking-[0.01em] text-zettlor-new-black;
}

.left-line-slide {
  left: calc(-50vw + 50%);
}

/* Navbar */
#navbar-logo {
  cursor: pointer;
}

#navbar-logo path {
  transform: translateX(-16px);
}

#navbar-logo:hover path {
  transform: translateX(0px);
}

#navbar-logo-letterE {
  transition: all 0.2s cubic-bezier(0.46, 0, 0.37, 1.38) 5ms;
}

#navbar-logo-letterT {
  transition: all 0.2s cubic-bezier(0.46, 0, 0.37, 1.38) 10ms;
}

#navbar-logo-letterT_2 {
  transition: all 0.2s cubic-bezier(0.46, 0, 0.37, 1.38) 15ms;
}

#navbar-logo-letterL {
  transition: all 0.2s cubic-bezier(0.46, 0, 0.37, 1.38) 20ms;
}

#navbar-logo-letterO {
  transition: all 0.2s cubic-bezier(0.46, 0, 0.37, 1.38) 25ms;
}

#navbar-logo-letterR {
  transition: all 0.2s cubic-bezier(0.46, 0, 0.37, 1.38) 30ms;
}

#navbar-logo-animated {
  transition: all 0.3s cubic-bezier(0.46, 0, 0.37, 1.38) 50ms;
}

#navbar-logo:hover #navbar-logo-letterZ,
#navbar-logo.simulate-hover #navbar-logo-letterZ {
  d: path(
    "M0 32.77V39.4464H34.8039V32.2136H26.9597L25.3463 34.4391L24.5118 34.0496L34.8039 7.23276V0.556366H0V7.78914H7.34353L8.95688 5.56366L9.79138 5.95312L0 32.77Z"
  );
}

/* Footer  */
.not-hovered-opacity-50 {
  opacity: 50%;
}

.progress-transition {
  transition: width 0.75s ease-out;
}

.mb-640 {
  margin-bottom: 160rem; /* 2560px */
}

.mb-320 {
  margin-bottom: 80rem; /* 1280px */
}

.mb-160 {
  margin-bottom: 40rem; /* 640px */
}

.mb-80 {
  margin-bottom: 20rem; /* 320px */
}

/* Booking UI responsive utilities */
@layer utilities {
  .booking-container {
    @apply px-4 md:px-6 box-border max-w-4xl mx-auto;
  }
  
  .date-header {
    @apply text-lg font-semibold text-gray-900 mb-2 py-2 px-3 bg-gray-50 rounded-md;
  }
  
  .time-slot-card {
    @apply relative rounded-lg border p-3 sm:p-4 transition-all duration-200 shadow-sm;
  }
  
  .time-slot-card-hover {
    @apply hover:shadow-md hover:translate-y-[-2px];
  }
  
  .time-slot-time {
    @apply text-xl font-semibold;
  }
  
  .time-slot-price {
    @apply text-2xl font-bold;
  }
  
  /* Responsive breakpoints */
  @screen sm {
    .booking-container {
      @apply px-6;
    }
    
    .time-slot-card {
      @apply p-4;
    }
  }
  
  @screen md {
    .booking-container {
      @apply px-8;
    }
  }
}
