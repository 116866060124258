@font-face {
  font-family: "Dragon";
  src: url("/fonts/dragon.ttf") format("truetype-variations");
  font-weight: normal;
  font-style: normal;
}

@layer utilities {
  .font-460 {
    font-variation-settings: "wght" 460;
  }
  .font-480 {
    font-variation-settings: "wght" 480;
  }
  .font-500 {
    font-variation-settings: "wght" 500;
  }
  .font-520 {
    font-variation-settings: "wght" 520;
  }
  .font-560 {
    font-variation-settings: "wght" 560;
  }
  .font-600 {
    font-variation-settings: "wght" 600;
  }
  .font-620 {
    font-variation-settings: "wght" 620;
  }
  .font-700 {
    font-variation-settings: "wght" 700;
  }
  .font-800 {
    font-variation-settings: "wght" 800;
  }
}
