div[data-radix-popper-content-wrapper] {
  z-index: 20 !important;
}
.tooltip-content {
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.tooltip-content[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.tooltip-content[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.tooltip-content[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.tooltip-content[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
