.ql-toolbar {
  @apply border-zettlor-new-black/20 !important;
  @apply sticky top-0 z-10 bg-zettlor-new-off-white px-2 py-5;
  @apply rounded-t-lg;
}

.ql-container {
  @apply border-zettlor-new-black/20 !important;
  @apply rounded-b-lg;
}

/* ql-display */
.ql-display {
  @apply break-words;
}

.ql-display h1 {
  @apply text-xl font-bold md:text-2xl;
}

.ql-display h2 {
  @apply text-lg font-bold md:text-xl;
}

.ql-display p {
  @apply mt-3 text-[18px] leading-[28px];
}

.ql-display blockquote {
  @apply my-4 border-l-4 border-zettlor-new-black/20 bg-zettlor-gray-200 p-4;
}

.ql-display ol {
  @apply mt-3  list-inside list-decimal;
}

.ql-display ol li {
  @apply mt-1;
}

.ql-display ul {
  @apply mt-3 list-inside list-none;
}

.ql-display ul li {
  @apply mt-1 pl-6 -indent-5;
}

.ql-display ul li:before {
  content: "●";
  font-size: 9px;
  @apply pr-3;
}

.ql-display .ql-indent-1 {
  @apply indent-2;
}
.ql-display .ql-indent-2 {
  @apply indent-4;
}
.ql-display .ql-indent-3 {
  @apply indent-6;
}
.ql-display .ql-indent-4 {
  @apply indent-8;
}
.ql-display .ql-indent-5 {
  @apply indent-10;
}
.ql-display .ql-indent-6 {
  @apply indent-12;
}
.ql-display .ql-indent-7 {
  @apply indent-14;
}
.ql-display .ql-indent-8 {
  @apply indent-16;
}

.ql-display a {
  @apply text-zettlor-new-orange underline;
}

/* ql-editor */
.quill.error .ql-container {
  @apply border-zettlor-danger-red bg-red-100 !important;
}

.ql-editor {
  overflow-y: scroll;
  min-height: 200px;
}

.ql-editor h1 {
  @apply text-xl font-bold;
}

.ql-editor h2 {
  @apply text-lg font-bold;
}

.ql-editor p {
  @apply text-sm;
}

.ql-editor p:not(:first-child) {
  @apply mt-3;
}

.ql-editor ul,
.ql-editor ol {
  @apply mt-3 pl-0;
}

.ql-editor ul li {
  @apply mt-1 pl-1 text-sm;
}

.ql-editor.ql-blank::before {
  @apply text-sm not-italic text-zettlor-gray-600;
}
